import React from 'react';
import './keywords.scss';

const keywords = () => {
  return (
    <>
        <div className='keywords d-none d-md-block'>
            <p className='header'>Keywords</p>
            <p>
                GHG = Greenhouse Gas Emissions and general pollution<br/>
                Jobs = The amount of jobs created by an initiative<br/>
                Cost = What the net expense is for an operation to take place<br/>
                Revenue = Funds that can be generated by using the selected assortment of waste technologies.<br/>
                LPS = Landfill Airspace Savings; the amount space saved in landfill sites.<br/>
                Range (100 to -1000) = A general indicator of success and change<br/>
            </p>
        </div>
        <div className='d-block' style={{width:'100%',height:50}}></div>
    </>
  )
}

export default keywords