import React from "react";
import Benefit from "../components/home/Benefit";
import CaseStudiesPreview from "../components/home/CaseStudiesPreview";
import ClimateChalleges from "../components/home/ClimateChalleges";
import GetInvolved from "../components/home/GetInvolved";
import Hero from "../components/home/Hero";
import ModelPreview from "../components/home/ModelPreview";
import PreviewAbout from "../components/home/PreviewAbout";
import PreviewAppCard from "../components/home/PreviewAppCard";
import WaitingList from "../components/home/WaitingList";
import InteractiveModel from "../components/interactive_model/InteractiveModel.js";
import CardsContainer from '../components/cards_container/cards_container'
import SustainabilityAndImpact from "../components/sustainability_and_impact/sustainability_and_impact";
import LandFillWaste from "../components/land_fill_waste/LandFillWaste";
import Challanges from "../components/challanges/challanges";
import ChangeMakers from "../components/change_makers/change_makers";
import InteractiveShowcase from "../components/interactive_showcase/interactive_showcase";
const Home =({map_url})=>{
    return(
        <>
        <Hero />
        <InteractiveShowcase map_url={map_url}/>
        <CardsContainer/>
        <SustainabilityAndImpact/>
        <LandFillWaste/>
        <Challanges/>
        <ChangeMakers margin={0}/>
        {/* <PreviewAppCard />
        <PreviewAbout />
        <InteractiveModel />
        <ModelPreview />
        <ClimateChalleges/>
        <Benefit/>
        <CaseStudiesPreview />
        <WaitingList />
        <GetInvolved /> */}
        </>
    )
}
export default Home;