import React,{useState,useEffect} from 'react';
import './publications_and_datasources.scss';
import Arrow from '../../icons/arrow';
import { Accordion } from '@blackboxsa/library';
import FunctionalityTable from '../functionality_table/functionality_table';
import DefaultScenario from '../default_scenarios/default_scenarios';
import WasteFractions from '../waste_fractions/waste_fractions';
import Collapse from '../collapse/Collapse';
const PublicationsAndDataSourcesComponent = () => {
    const [accordionOpen, setAccordionOpen] = useState([false]);
    const [openIndex, setOpenIndex] = useState(null);

    const [accordionOpen1, setAccordionOpen1] = useState([false]);
    const [openIndex1, setOpenIndex1] = useState(null);
    const data = [
        { title: "Functionality table", copy: <FunctionalityTable/> ,height:2400 },
        ]
    const data1 =[
        { title: "Default scenarios", copy: <DefaultScenario/>,height:2400  },
        { title: "Waste Fractions", copy: <WasteFractions/>,height:2400  },
        ]

    useEffect(() => {
        const index = accordionOpen.indexOf(true);
        const index1= accordionOpen1.indexOf(true);
        setOpenIndex(index)
        setOpenIndex1(index1)
      },[accordionOpen,accordionOpen1])
  return (
    <div className='publications_and_datasources'>
        <div className='container'>
            <h1 className='publications_and_datasources--header'>Publications and<br/>
                Data Sources
            </h1>
            <p className='publications_and_datasources--content'>The WROSE team has created a “user guide” consisting of quality resources in
                 order to assist users and policy makers interested in growing the waste sector.</p>
        <Collapse data={data} chevron={<Arrow />}/>
            {/* <Accordion
                style={{ width: "100%"}}
                data={data}
                width={0}
                chevron={<Arrow />}
            /> */}
            <a style={{textDecoration:'none', pointerEvents: 'none'}} href='/' target='_blank'>
                <div className='source-container w-100 d-flex justify-content-between align-items-center'>
                    <p className='m-0'>Indicators</p>
                    <Arrow/>
                </div>
            </a>
            <a style={{textDecoration:'none', pointerEvents: 'none'}} href='/' target='_blank'>
                <div className='source-container w-100 d-flex justify-content-between align-items-center'>
                    <p className='m-0'>Process diagrams</p>
                    <Arrow/>
                </div>
            </a>
            <Collapse data={data1} chevron={<Arrow />}/>
            {/* <Accordion
                style={{ width: "100%"}}
                data={data1}
                width={0}
                chevron={<Arrow />}
            /> */}
        </div>
    </div>
  )
}

export default PublicationsAndDataSourcesComponent