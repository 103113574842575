import React, { useEffect } from "react";
import Hero from "../components/hero/hero";
import '../components/about/About.scss'
import InteractiveModel from "../components/interactive_model/InteractiveModel";

const Demo = ({map_url})=>{
    useEffect(() => {
        window.scrollTo({top:0,left:0,behavior:"instant"});
    })
    return(
        <div className="about">
            <Hero class='demo-banner' />
            <InteractiveModel map_url={map_url}/>
        </div>
    )
}
export default Demo;