
const model_for_data = [
    {
        header:'National & local governments',
        sentence:'Governmental departments that are developing waste management policies as well as GHG emissions mitigation strategies and MPAs (mitigation potential analysis).',
    },
    {
        header:'Solid waste planners & decision-makers',
        sentence:'Provincial and Local Governmental departments and Municipalities involved in the waste management decision-making process and implementation of the IWMPs.',
    },
    {
        header:'Students & researchers',
        sentence:'WROSE can assist students and researchers in evaluating different waste management technologies.',
    },
    {
        header:'Private industries & waste management organizations',
        sentence:'WROSE is a flexible tool for any organisation in their transition into a circular and low-carbon economy.',
    },
]

const the_benefits = [
    {
        header:'',
        sentence:'The WROSE model aims to aid the waste managers in determining the most appropriate waste management strategy / scenario / technology that responds to all four levels of sustainability. WROSE is uniquely suited to waste management in that it has the following benefits:',
    },
    {
        header:'Scenario Evaluation',
        sentence:'After inserting waste inputs, the WROSE model can identify and assess waste treatment scenarios that are evaluated on the basis of several sustainability indicators: environmental, techno-economic, social and institutional.',
    },
    {
        header:'Optimisation',
        sentence:'With each unique set of waste strategies the model can optimise existing waste management infrastructure or design/develop new and improved scenarios.',
    },
    {
        header:'Decision making',
        sentence:'At the final stage, the WROSE model generates a report of various outcomes that informs the final choice of an optimised waste scenario, in line with the integrated waste management plan or strategy document of the user.',
    },
]

module.exports = {model_for_data,the_benefits}