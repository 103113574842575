import React,{useContext} from 'react';
import { GlobalAttributes } from "../../context/screenSize";
import './switch.css'

const ToggleSwitch = ()=>{
  const { toggleState } = useContext(GlobalAttributes);
  const { setToggleState } = useContext(GlobalAttributes);
  const handleToggle = () => {
    //Set off cookies
    // var gtm_header = document.getElementById('header-gtm');
    // var userback = document.getElementById('userback-script');

    // if (toggleState === false){
    //   gtm_header.type = "text/javascript";
    //   userback.type = "text/javascript";
    // }
    // else {
    //   gtm_header.type = "text/plain";
    //   userback.type = "text/plain";
    // }
    setToggleState(!toggleState);
  }
  return (
    <>
    <div onClick={handleToggle} className="ToggleSwitch" id="ToggleSwitch">
      <div className={toggleState ? 'knob active' : 'knob'} />
    </div>
    </>
  )
}
export default ToggleSwitch;