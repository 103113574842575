import React from 'react';
import './model_for.scss';

const model_for = ({data,icons}) => {
  return (
    <>
    {data.map((item,i) => {
        return(
            <div className='model_for'>
            <div className='d-flex'>
               {icons[i] && <div className='circle'><img src={icons[i]} alt='icon svg'/> </div>}
                 <h4>{item.header}</h4>
            </div>
            <p>{item.sentence}</p>
        </div>
        )
    })

    }
</>
  )
}

export default model_for