import React from "react";
import AppHolding from "../components/app_holding/app_holding";

const AppHoldingTemplate = ()=>{
    return(
        <div>
            <AppHolding />
        </div>
    )
}
export default AppHoldingTemplate;