import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0846 17.8229C12.2082 17.9413 12.354 18.0341 12.5136 18.0959C12.8301 18.2259 13.1851 18.2259 13.5016 18.0959C13.6612 18.0341 13.807 17.9413 13.9306 17.8229L17.8306 13.9229C18.0754 13.6781 18.2129 13.3461 18.2129 12.9999C18.2129 12.6537 18.0754 12.3217 17.8306 12.0769C17.5858 11.8321 17.2538 11.6946 16.9076 11.6946C16.5614 11.6946 16.2294 11.8321 15.9846 12.0769L14.3076 13.7669V9.09993C14.3076 8.75515 14.1706 8.42449 13.9268 8.18069C13.683 7.93689 13.3524 7.79993 13.0076 7.79993C12.6628 7.79993 12.3321 7.93689 12.0883 8.18069C11.8446 8.42449 11.7076 8.75515 11.7076 9.09993V13.7669L10.0306 12.0769C9.90973 11.9551 9.76595 11.8584 9.60754 11.7924C9.44912 11.7264 9.2792 11.6924 9.10759 11.6924C8.93597 11.6924 8.76605 11.7264 8.60764 11.7924C8.44922 11.8584 8.30544 11.9551 8.18459 12.0769C8.06274 12.1978 7.96603 12.3416 7.90003 12.5C7.83403 12.6584 7.80005 12.8283 7.80005 12.9999C7.80005 13.1715 7.83403 13.3415 7.90003 13.4999C7.96603 13.6583 8.06274 13.8021 8.18459 13.9229L12.0846 17.8229Z"
      fill="#70C4C9"
    />
    <path
      d="M13 26C15.5712 26 18.0846 25.2376 20.2224 23.8091C22.3602 22.3806 24.0265 20.3503 25.0104 17.9749C25.9944 15.5994 26.2518 12.9856 25.7502 10.4638C25.2486 7.94208 24.0105 5.6257 22.1924 3.80762C20.3743 1.98953 18.0579 0.751405 15.5362 0.249797C13.0144 -0.251811 10.4006 0.00563269 8.02511 0.989572C5.64967 1.97351 3.61935 3.63975 2.19089 5.77759C0.762437 7.91543 0 10.4288 0 13C0 16.4478 1.36964 19.7544 3.80761 22.1924C5.01477 23.3995 6.44788 24.3571 8.02511 25.0104C9.60235 25.6637 11.2928 26 13 26ZM13 2.60001C15.0569 2.60001 17.0677 3.20995 18.7779 4.35272C20.4882 5.49549 21.8212 7.11975 22.6083 9.0201C23.3955 10.9204 23.6014 13.0115 23.2002 15.0289C22.7989 17.0463 21.8084 18.8994 20.3539 20.3539C18.8994 21.8084 17.0463 22.7989 15.0289 23.2002C13.0115 23.6015 10.9204 23.3955 9.02009 22.6083C7.11974 21.8212 5.49548 20.4882 4.35272 18.7779C3.20995 17.0677 2.6 15.0569 2.6 13C2.6 10.2418 3.69571 7.59647 5.64609 5.64609C7.59647 3.69572 10.2417 2.60001 13 2.60001Z"
      fill="#018186"
      fillOpacity={0.5}
    />
  </svg>
);
export default SVGComponent;
