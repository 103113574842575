import React, { useState } from 'react';
import './modal_content.scss';
import { RadioButton,Button } from '@blackboxsa/library';
import RightArrow from '../../icons/right-arrow'
import axios from 'axios';

const ModalContent = ({email, setModal,setEmailSending}) => {

  const [checked, setChecked] = useState('');

  const handleCheckboxChange = (event) => {
    setChecked(event.target.value);

  };

  const handleSubmit = () => {
    // Handle the submission of the checked value here
   
    axios.post('/send_app_info', {email, type: checked})
    .then(() => {
       
        window.scrollTo(0, 0)

    })
    .catch(error => {
        console.warn('font-end error: ', error)
    }).finally(()=>{
      setModal(false)
      setEmailSending(false)
    })

  };

  return (
    <div className='modal-content'>
      <h1>We would love to find out a little bit more about you. Let us know what kind of user you are.</h1>
      <div>
        <div className='modal-content--container'><RadioButton label="Private" name="userType" value="private" radio={true} onChange={handleCheckboxChange} checked={checked === 'private'} /></div>
        <div className='modal-content--container'><RadioButton label="Researcher" name="userType" value="researcher" radio={true} onChange={handleCheckboxChange} checked={checked === 'researcher'} /></div>
        <div className='modal-content--container'><RadioButton label="Municipal" name="userType" value="municipal" radio={true} onChange={handleCheckboxChange} checked={checked === 'municipal'} /></div>
      </div>
      <Button
        text='Send'
        type='submit'
        color={'black'}
        variant={'filled'}
        rounded={false}
        width={105}
        iconPos='right'
        textColor='white'
        onClick={handleSubmit}
        icon={<RightArrow/>}
        disabled={ checked === ' ' ? true : false }
    />
    </div>
  );
};

export default ModalContent;
