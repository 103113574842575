import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './components/global/Footer';
import Navbar from './components/global/Navbar';

import { ContactUs } from './components/contact_us/ContactUs';
import About from './pages/About';
import PublicationsAndDataSources from './pages/PublicationsAndDataSources';
import Resources from './pages/Resources';
import CaseStudy from './pages/CaseStudy';
import AppHoldingTemplate from './pages/AppHolding'
import Home from './pages/Home';
import Demo from './pages/Demo'
import { AttributeProvider } from './context/screenSize';
import { ScrollToTop } from './ScrollToTop';
import Cookies from './components/Privacy/cookies';

import './App.scss';
function App() {

  return (

<BrowserRouter>
  <ScrollToTop/>
  <AttributeProvider>
    <div className="app">
      <Navbar map_url="https://map.wrose.co.za/" />
      <Routes>
        <Route exact path="/" element={<Home map_url="https://map.wrose.co.za/"/>} />
        <Route exact path="/about" element={<About scroll={true}/>}/>
        <Route exact path="/about_us" element={<About scroll={false}/>}/>
        <Route exact path="/publications_and_datasources" element={<PublicationsAndDataSources/>}/>
        <Route exact path="/resources" element={<Resources map_url="https://map.wrose.co.za/" scroll={true}/>}/>
        <Route exact path="/resource" element={<Resources map_url="https://map.wrose.co.za/" scroll={false}/>}/>
        <Route exact path="/case_study" element={<CaseStudy/>}/>
        <Route exact path="/contact_us" element={<ContactUs/>}/>
        <Route exact path="/app_holding" element={<AppHoldingTemplate/>}/>
        <Route exact path="/demo" element={<Demo map_url="https://map.wrose.co.za/"/>}/>
      </Routes>
      <Footer />
      <Cookies/>
    </div>
  </AttributeProvider>
</BrowserRouter>



  );
}
export default App;
