export const Content = ({ contentBody, titles,selected, contentStyle,mobile }) => {


    return <div className="tab-content" id="v-pills-tabContent" style={contentStyle}>

            <div className="tab-pane fade show active" id="v-pills-anaerobic" role="tabpanel" aria-labelledby="v-pills-anaerobic-tab">
                <h5 style={mobile ? {margin: "28px 0 28px 0"} : {margin:"0 0 22px 0"}}>{titles[selected]}</h5>
                <p> {contentBody[selected]} </p>
            </div>
    </div>
}

export const bodies = ['Anaerobic digestion (AD) is a biological process in which biodegradable organic matter such as food waste is broken down by microorganisms in the absence of oxygen, into biogas and a stable solid called "digestate".',
"The biogenic waste fraction refers to food waste or garden refuse which is the waste fraction that can be aerobically composted or anaerobically digested. To find out more read our article.",
'Aerobic composting is a biological process in which the biological degradation of biogenic waste, in the presence of oxygen, takes place.The process produces carbon dioxide, ammonia, water and humus or compost thus ensuring a stabilisation of the biogenic waste.',
'WROSE was initially developed with five scenarios selected as most relevant/appropriate to waste management for South African Municipalities. These include landfilling, Landfillling with LFG Recovery/Flaring, LFG Rec/Electricity generation, Recycling, Aerobic Composting, Anaerobic Digestion',
` TROIS, C. & JAGATH, R. 2011. Sustained carbon emissions reductions through zero waste strategies for South African municipalities. In Waste Management, INTECH Publications. ISBN 978-953-307-179-4.
FRIEDRICH, E. & TROIS, C. 2011. Quantification of greenhouse gas emissions from waste management processes for municipalities–A comparative review focusing on Africa. Waste management, 31, 1585-1596.
FRIEDRICH, E. & TROIS, C. 2013a. GHG emission factors developed for the collection, transport and landfilling of municipal waste in South African municipalities. Waste management, 33, 1013-1026.
FRIEDRICH, E. & TROIS, C. 2013. GHG emission factors developed for the recycling and composting of municipal waste in South African municipalities. Waste management, 33, 2520-2531.
FRIEDRICH, E. & TROIS, C. 2016. Current and future greenhouse gas (GHG) emissions from the management of municipal solid waste in the eThekwini Municipality–South Africa. Journal of cleaner production, 112, 4071-4083..
KISSOON, S. 2018. The Advancement of the Waste Resource Optimization and Scenario Evaluation Model: The Inclusion of Socio-Economic and Institutional Indicators. Master of Science in Civil Engineering Thesis, University of KwaZulu-Natal, Durban, South Africa.`,
' The amount of energy consumption associated with material production or end-of-life management of a material.',
` A greenhouse gas (GHG) is a gas from natural and human-made sources that absorb and emit infrared radiation.
GHGs create the greenhouse effect where radiation emitted by the Earth is absorbed and trapped in the atmosphere, creating a global warming effect.
Carbon dioxide (CO2), methane (CH4), and nitrous oxide (N2O) are among three of the most common GHGs.`,
"A quantitative or qualitative measure of waste management technologies performance.The model evaluates the following indicators: GHG emissions, Landfill space saving, waste diversion rate, energy use, capital cost, operational cost, revenue, direct and indirect health risks associated with the jobs created, job creation potential and institutional indicators.",
"Landfilling refers to the deposition of waste onto land, this includes the filling in of waste into an excavation or the creation of a landfill above ground and then the ‘filling’ of it.",
"The input waste material is the quantity of waste fraction in tons.",
"The disposal of biogenic waste into a sanitary landfill decomposes naturally emitting methane gas. A network of pipes underground works as a vacuum, sucking out the gas and transporting it through a series of processes that allows it to be transformed into a renewable energy source.LFG Rec/Flaring System is a basic flare that is used primarily for the combustion of excess landfill or other biogas generated by solid waste landfills.",
" Landfill space saving calculations can be used to determine the amount of air space that is saved through the diversion of waste by recycling, composting and anaerobic digestion.",
" Material recovery facilities (MRF) forms part of an overall waste treatment process. Within MRF waste is separated into a recyclable fraction and a residual fraction. All recyclables are bailed and sent to waste processing facilities and the residual waste is then sent to a sanitary landfill.",
"The total emissions produced during the complete life-cycle of a material, from raw material acquisition and production of a material through the completion of the waste management process.",
"The recyclable fraction is a waste material that can be reused for the manufacturing of new products. All recyclables are bailed and sent to waste processing facilities, and the residual waste is then sent to a sanitary landfill.",
" Recycling is the reprocessing or reuse of used and waste material into new materials and products. The United Nations (2007) formal definition of recycling states that recycling is any reintroduction of waste materials in a production process that diverts it from the waste stream.",
"The residual fraction of waste is the waste that is left over once the reusable materials are extracted and is landfilled with the inclusion of landfill gas recovery for the generation of electricity.",
"Socio-Economic Indicators in the WROSE model are job creation potential, direct and indirect health risks associated with those jobs, public acceptance and social perception.",
"The landfill diversion rate was approximated by calculating the total amount of waste diverted from landfilling divided by the total amount of waste entering the waste stream. The diverted waste could be due to waste being diverted to recycling, anaerobic digestion, composting or a combination thereof.Waste diversion rate (%) = Total quantity of waste diverted (tons) / total quantity of waste entering the waste stream (tons)",
"Waste management encompasses various practices which may reduce the quantity of waste produced, improve waste collection and disposal methods. Technologies are the mechanims by which this is achieved "
]

export const titles = ["Anaerobic digestion" ,"Biogenic waste fraction",
                    "Composting", "Default scenarios", "Data sources",
                    "Energy use","GHG emissions","Indicator","Input waste",
                "Landfilling", "Landfilling gas rec/flaring", "Landfilling space saving",
                "Material recovery facilities (MRF)", "Net emissions", "Recyclable fraction",
                "Recycling", "Residual fraction","Socio-Economic indicators",
                "Waste diversion rate","Waste management technologies"]