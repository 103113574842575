export const chartData = [
    { id: 1, technology: "Landfill (LF)", ghc: 26, numJobs: 5, capCost: 5, revenue: 0, lps: 0, selected: true },
    { id: 2, technology: "Landfill Gas Rec (LFG)", ghc: -11, numJobs: 20, capCost: 10, revenue: 8, lps: 0, selected: true },
    { id: 3, technology: "Recycling (RC)", ghc: -19, numJobs: 8, capCost: 60, revenue: 44, lps: 39, selected: true },
    { id: 4, technology: "Anaerobic Digestion (AD)", ghc: -10, numJobs: 12, capCost: 20, revenue: 10, lps: 46, selected: true },
    { id: 5, technology: "Aerobic Composting (AC)", ghc: 10, numJobs: 6, capCost: 2, revenue: 5, lps: 46, selected: true },
]

export const fractions = [
    "Paper and cardboard",
    "Plastics",
    "Glass",
    "Metals",
    "Biogenic waste",
    "Unsorted and untreated waste"
]

