import { Button } from '@blackboxsa/library';
import React,{useContext} from 'react';
import { useNavigate } from 'react-router-dom'
import RightArrow from '../../icons/right-arrow';
import './case_study_card.scss';
import IEA from './claudio-schwarz-mLo0kohXZhs-unsplash 1.png'
import DTP from './DTP1 1.png'
import BIO from './thomas-richter-B09tL5bSQJk-unsplash 1.png'
import Logo from './Logo.png';
import Grey from './thomas-richter-B09tL5bSQJk-unsplash 1 (1).png'
import DPTSupport from './DTP1 1 (1).png';
import DPTSupportMobile from './DTP1-mobile.png';

import biohydrogen from '../../assets/pdfs/Biohydrogen production.pdf'
import biodigestors from '../../assets/pdfs/IEA Bioenergy Task 36 - Biodigesters.pdf'
import policyReport from '../../assets/pdfs/IEA Bioenergy Task 36 - Policy report.pdf'
import stakeHolderEngagement from '../../assets/pdfs/WROSE Workshop 1 Nell and Trois - Stakeholder Engagement.pdf'
import workshop2 from '../../assets/pdfs/WROSE Workshop 2 DellOrto and Trois - Biohydrogen.pdf'
import workshop3 from '../../assets/pdfs/WROSE Workshop 3 Kissoon and Trois - Plastic waste GRDM.pdf'

import { GlobalAttributes } from "../../context/screenSize";

const CaseStudyCards = () => {
  const {setCaseStudyContent,setNavBg} = useContext(GlobalAttributes);
  const navigate = useNavigate()

  const data = [
    {
        title:'IEA Bioenergy Task 36: Biodigesters',
        sub_title:'A case study on the potential of waste management assessed using the WROSE model.',
        paragraph:`Approximately 40 tons of fresh produce is generated weekly at the Dube Trade Port Agri-Zone. This also generates large quantities of waste made up of a significant organic fraction.
          <br/> <br/>
        The waste management plan for this agri-zone is broken up into two phases.
        <br/> <br/>
        Phase 1:
        16 Ha of greenhouses are to be build along with the use of post-harvest pack-houses. A central packing and distrobution center and a sursey will significantly reduce the amount of waste that ends up in landfills. Lastly, the Dube AgriLab, a plant culture laboratory, will be instramental in the waste management plan.
        <br/> <br/>
        Phase 2:
        Greenhouses and tunnels, in addition of packing and distribution facilities will be the next step in reducing waste. Along with that there will be significant emphasis paced on waste-to-energy operations. `,
        image:DTP,
        link: biodigestors,
        supportImage:DPTSupport,
        suuportImageMobile:DPTSupportMobile,
        keywords:'LCA; Waste management; ORWARE; Martes; Costs'

    },
    {
        title:'IEA Bioenergy Task 36: Policy report',
        sub_title:'A policy report on the potential of waste management assessed using the WROSE model.',
        paragraph:'This report is a review of the Waste-to-Energy (WtE) policy in South Africa within the framework of the IEA Bioenergy Task 36. In addition, drivers and barriers in the implementation of WtE solutions in different counties (i.e.,Germany, Ireland, Italy,Norway, Sweden and United States of America) are also presented and discussed. <br/> <br/> Thepurpose of this review is to provide countries with inspiration and support in implementing suitable policies and solutions in the waste-to-resources managementand WtE sector that would facilitate their transition towards circularity.The IEA Bioenergy Task 36, working on the topic “Material and Energy Valorisation ofWaste in a Circular Economy”, seeks to raise public awareness of the sustainableenergy generation from biomass residues and waste fractions including municipal solidwaste (MSW) as well as to increase technical information dissemination.<br/><br/> As outlined in the 3-year work programme, Task 36 seeks to understand what role energy from wasteand material recycling can have in a circular economy and identify technical and nontechnical barriers and opportunities needed to achieve this vision. See <a href="http://task36.ieabioenergy.com/" target="_blank">http://task36.ieabioenergy.com/</a> for links to the work performed by IEA BioenergyTask 36.',
        image:IEA,
        link: policyReport,
        supportImage:'',
        suuportImageMobile:'',
        keywords:'Incineration, Pyrolysis, Conventional Gasification, Plasma Arc Gasification, Anaerobic Digestion, Landfilling with Gas Extraction, Waste Minimisation, Waste Prevention, Waste as a Resource, Sustainable Strategic Partnerships, Environmentally sound socio-economic growth and development'

    },
    // {
    //     title:'World Bank project',
    //     sub_title:'Neque, ultrices sed eget sollicitudin volutpat tellus. Nibh laoreet fames eu dui faucibus ha...',
    //     paragraph:'',
    //     image:Grey,
    //     link:'',
    //     supportImage:'',
    //     suuportImageMobile:'',
    //     keywords:''

    // },
    // {
    //     title:'Waste-to-energy Roadmap',
    //     sub_title:'Neque, ultrices sed eget sollicitudin volutpat tellus. Nibh laoreet fames eu dui faucibus ha...',
    //     paragraph:'',
    //     image:Grey,
    //     link:'',
    //     supportImage:'',
    //     suuportImageMobile:'',
    //     keywords:''

    // },
        {
        title:'Biohydrogen Production',
        sub_title:'Considerations on bio-hydrogen production from organic waste in South African municipalities: A review',
        paragraph:'Organic waste disposal contributes to 3.8% of GHG emissions to the atmosphere, yet 68.8% of thisputrescible waste fraction is still disposed of, untreated, to landfills in South Africa. The implementationof a ban on disposal of organic waste to landfills at provincial level opens up the need to research besttechnology pathways and waste minimisation strategies to valorise and promote the circularity of diverted waste streams. The SARChI Chair in Waste and Climate Change has developed the WROSETM (WasteResource Optimization Scenario Evaluation) model to assist municipalities in selecting the most appropriatewaste management solutions. A research gap has been identified in the lack of information on full-scaleapplications of two-stage anaerobic digestion (2-stage AD) for combined bio-hydrogen and bio-methaneproduction from organic waste. In this review, we explore drivers and barriers to the implementation of2-stage AD in South Africa and propose possible scenarios using the WROSETM model for its insertion intoan Integrated Waste Management System at municipal level. A literature analysis suggests that 2-stage ADis a potentially viable solution to recover the inherent value of organic waste and promote circularity usingbio-hydrogen and bio-methane. However, the currently available organic fraction in the municipal solidwaste streams is not a suitable feedstock, as it requires high levels of pre-treatment. Suitable scenariosusing the WROSETM model are proposed for South African municipalities, paving the way for future researchtowards the scale-up of this technology.',
        image:BIO,
        link: biohydrogen,
        supportImage:'',
        suuportImageMobile:'',
        keywords:'2-stage AD, bio-hydrogen, organicwaste, Integrated Waste Management System, WROSETM model'

    },
    {
        title:'WROSE workshop 1: Stakeholder engagment',
        sub_title:'Waste-to-Energy, stakeholder engagement, waste to resources, waste beneficiation, participatory approach',
        paragraph:'This paper presents the results of an initial stakeholder engagement process that was undertaken during the development phase of South Africa’s Waste-to-Energy (WtoE) Roadmap. Targeted stakeholder engagements were held with individuals or groups belonging to one of the following groups: regulatory stakeholders, project implementers, financial institutions, industry, other influencing stakeholders and research institutions. Most emphasis was placed on engagement with regulatory stakeholders, project implementers and financial institutions. Inputs derived from the stakeholder engagement sessions were thematically analysed. Initial analysis shows that discussions centred around ten central themes namely: Drivers for WtoE implementation; Barriers to WtoE implementation; Opportunities for the WtoE Roadmap; Waste stream or feedstock related inputs; Technology related discussions; Recommendations for WtoE Roadmap enhancement; Policy related discussions; Funding related discussions; Business models or economic feasibility related discussions; and Alignment opportunities identified. Two major findings from this process are firstly, that there are many opportunities for the WtoE Roadmap to align to national mandates and imperatives, and secondly that most stakeholders engaged agree that the beneficiation of the organic waste fraction through WtoE technologies such as anaerobic digestion and gas to energy is important. It was further agreed that hazardous and medical waste could also be ideal feedstock for WtoE applications. Stakeholder engagement during this initial phase of the Roadmap development was not exhaustive and therefore further and more in-depth engagement during the next phases of the Roadmap development is critical.',
        image:Logo,
        link: stakeHolderEngagement,
        supportImage:'',
        suuportImageMobile:'',
        keywords:'Waste-to-Energy, stakeholder engagement, waste to resources, waste beneficiation, participatory approach'

    },
    {
        title:'WROSE workshop 2: Biohydrogen',
        sub_title:'Potential Hydrogen production municipalities for combined Bio and Bio Methane in South Africa',
        paragraph:'South Africa is experiencing a waste management problem, with sanitary landfills rapidly filling up. Current population growth and rising urbanisation are bound to exacerbate this issue. It is paramount to find alternatives to landfilling to extend the lifespans and reduce the carbon emissions of the waste sector. The national and local governments are setting targets and implementing bans to encourage the diversion of biodegradable waste from landfills, but South Africa lacks alternatives that can fully valorise the economic potential of organic waste, especially at the municipal level. This study aims to identify the gaps and suggest a pathway for implementing a novel technique, double-stage anaerobic digestion (2S-AD), and the potential scaling up to full scale. The WROSETM model is utilised to assess the viability of 2S-AD in South African municipalities using a series of environmental, techno-economic, social and institutional factors. Several scenarios based on source-separated organic fractions are proposed to prepare the insertion into an Integrated Waste Management Plan and will be further developed in future research.',
        image:Logo,
        link: workshop2,
        supportImage:'',
        suuportImageMobile:'',
        keywords:'2-stage AD, bio-hydrogen, organic waste, Integrated Waste Management System, WROSETM model'

    },
    {
        title:'WROSE workshop 3: Plastic Waste GRDM',
        sub_title:'Plastic waste management for Landfill Space Savings and GHG Emission reduction in the Garden Route District',
        paragraph:'The recycling of plastic packaging materials has expanded rapidly over the years. This is due to an increase in technological advancements and the awareness of materials widely available for recycling. Through combined efforts from government, industry and the general public, larger amounts of plastic waste have the potential to be removed from the municipal solid waste stream and has the ability to further enhance the recycling market in South Africa, thereby contributing to landfill airspace savings. The aim of this study is to determine the potential landfill diversion rates and landfill airspace savings that can be achieved through the extraction of low density polyethylene (LDPE )and polyethylene terephthalate (PET) plastic polymer fractions from the municipal solid waste stream over a 50-year period using the Garden Route District Municipality as a case study. In addition, the GHG emissions and emission reduction will also be included. The study employs the Waste Resource Optimization and Scenario Evaluation (WROSE) model as a methodology to select the scenarios that will be assessed. The scenarios identified were, the collection and disposal of unsorted untreated MSW as the business-as-usual scenario. The second scenario was the collection of unsorted, untreated MSW to a mechanical pre-treatment facility and ultimately recycling. The outcome of the study derived a 1.5% diversion rate from landfill when considering the extraction of the PET and LDPE fractions as a whole from the total waste generated. Landfill space savings are seen to increase as larger amounts of PET and LDPE are extracted from landfills. GHG emissions are low in the business as usual scenario due to emissions from the disposal of LDPE and PET to landfill being contributed to from the transportation of plastic waste. However, GHG emission reductions are seen when PET and LDPE fractions are extracted.',
        image:Logo,
        link: workshop3,
        supportImage:'',
        suuportImageMobile:'',
        keywords:''

    },
]

  const passContent = (item) => {
    setCaseStudyContent(item)
    setNavBg(true)
    navigate('/case_study')
  }

  return (
    data.map((item,i) => {
      return(
    <div className='case_study_card d-md-flex' key={"key_number_" + i.toString()}>
        <div className='case_study_card--image_container'><img src={item.image}/></div>
        <div className='case_study_card--panel'>
            <h6>{item.title}</h6>
            <p>{item.sub_title}</p>
            <a onClick={() => passContent(item)}>Read more</a>

        </div>
    </div>
      )
  })
  )
}

export default CaseStudyCards;