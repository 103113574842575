import React from 'react'
import './land-fill-waste.scss'
import chevron from './angle-left-b.svg'
const LandFillWaste = () => {
  return (
    <div className='land-fill-waste'>
        <div className='shade'>
            <div className='container'>
            <h1>80%<span>*</span> of waste that<br/>ends up in landfills<br/>could have been<br/>diverted</h1>
            <p className='grey-text'>*(State of the Waste Report, DFFE, 2018)</p>
            <p>
                The WROSE model is driven by the purpose of tackling climate change. As outlined by the 2030 Agenda for Sustainable Development and the 17 Sustainable Development Goals (SDGs), there are a number goals and challenges, some of which directly inspire and motivate the WROSE model.
            </p>
            <h5>Global challenges that<br/>
            motivate the WROSE model</h5>
            <p>Supporting the just transition towards a decarbonised
                and a circular waste sector</p>
              <a href="#sliders"><img className="chevron" src={chevron} /></a>
            </div>
        </div>
    </div>
  )
}

export default LandFillWaste