import React,{useState, useEffect} from "react";
import image from './Logo.png'
import image_mobile from './svgs/Logo-mobile.svg'
import chevron from './svgs/angle-left-b.svg'
import './hero.scss';
import FadeIn from 'react-fade-in';
const Hero = ()=>{
    const [width, setWidth] = useState(window.innerWidth);
    const [loaded, setLoaded] = useState(false);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        setLoaded(true)
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    return(
        <div className={`main-hero`}>
        <FadeIn delay={400} transitionDuration={1000}>
            <div className={`hero`}>
                <div className="container">
                    <FadeIn delay={700} transitionDuration={1000}>
                        <div className="row">
                            <div className="col-12 hero-content">
                                <img style={{width:'50%'}} src={ isMobile ? image_mobile:image} />
                                <p className="hero--text">waste resource optimisation and scenario evaluation</p>
                                <a href="#i_s"><img className="chevron" src={chevron} /></a>
                            </div>
                        </div>
                    </FadeIn>
                </div>
            </div>
            </FadeIn>
            </div>
    )
}
export default Hero;