import React, { useState, useEffect } from "react";
import innovation from '../../assets/carousel-updated/innovation.jpg'
import inequalities from '../../assets/carousel-updated/reduce-inequalities.jpg'
import energy from '../../assets/carousel-updated/clean-energy.jpg'
import water from '../../assets/carousel-updated/life-below-water.jpg'
import growth from '../../assets/carousel-updated/economic-growth.jpg'
import consumption from '../../assets/carousel-updated/responsible-consumption.jpg'
import climate from '../../assets/carousel-updated/climate-action.jpg'
import clean from '../../assets/carousel-updated/clean-water.jpg'
import arrow from './arrow-svg.svg'
import sustainable from '../../assets/carousel-updated/sustainable-cities.jpg'
import './slider.scss'

const ClimateChallenges = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedMobile, setSelectedMobile] = useState(0)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {

    if (isMobile) {

      let touchStartX = 0;
      let touchEndX = 0;

      const handleTouchStart = (event) => {

        touchStartX = event.touches[0].clientX;
      };

      const handleTouchEnd = (event) => {

        touchEndX = event.changedTouches[0].clientX;
        const touchDiff = touchEndX - touchStartX;
        const swipeThreshold = 50;

        if ((touchDiff > swipeThreshold) && selectedMobile > 0) {

          setSelectedMobile((prevSelectedMobile) => prevSelectedMobile - 1);
        } else if (((swipeThreshold + touchDiff) < 0) && selectedMobile < 8) {

          setSelectedMobile((prevSelectedMobile) => prevSelectedMobile + 1);
        }
      };

      const element = document.getElementById("sliders");
      element.addEventListener("touchstart", handleTouchStart);
      element.addEventListener("touchend", handleTouchEnd);

      const sliderElements = element.querySelectorAll(".slider");

      sliderElements.forEach((element, index) => {
        if (index === selectedMobile) {
          element.classList.remove("d-none");
          element.classList.remove("hide");
          element.classList.add("active");
        } else {
          element.classList.add("d-none");
          element.classList.add("hide");
          element.classList.remove("active");
        }
      });

      return () => {
        element.removeEventListener("touchstart", handleTouchStart);
        element.removeEventListener("touchend", handleTouchEnd);
      };
    }
  }, [isMobile, selectedMobile]);

  const makeWidthShorter = (event) => {

    var x = document.getElementById("sliders").querySelectorAll(".slider");
    x.forEach((element) => {
      if (element.classList.contains("active")) {
        element.classList.remove("active");
      }
    });

    var classNames = Array.from(event.currentTarget.classList);
    classNames.push("active");
    event.currentTarget.classList = classNames.join(" ");

    if (isMobile) {
      var count = 0;
      x.forEach((element) => {
        element.classList.add("d-none");
      });
      x.forEach((element) => {
        if (element.classList.contains("active")) {
          element.classList.remove("d-none");
          if (x[count - 1]) {
            x[count - 1].classList.remove("d-none");
          }
          if (x[count + 1]) {
            x[count + 1].classList.remove("d-none");
          }
        }
        count = count + 1;
      });
    }
  };

  const handleMouseEnter = (event) => {
    event.currentTarget.classList.add("active");
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.classList.remove("active");
  };

  return (<div className="climate-challenges">
    <div className="container">
      {
        isMobile && <>
          {(selectedMobile > 0) && <img src={arrow} className="left-arrow"></img>}
          {(selectedMobile < 8) && <img src={arrow} className="right-arrow"></img>}
        </>
      }

      <div className="row" id="sliders" onTouchStart={() => { }} onTouchEnd={() => { }} style={{ overflowX: "hidden", overflowY: "hidden" }}>
        <div className={`col slider`} style={{ background: `url(${innovation})` }} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={makeWidthShorter}>
          <span className="tag">SDG 12</span>
          <h6>Innovation and infrastructure</h6>
          <p>Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation</p>
        </div>
        <div className="col slider" style={{ background: `url(${inequalities})` }} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={makeWidthShorter}>
          <span className="tag">SDG 10</span>
          <h6>Reduced inequalities</h6>
          <p>Reduce inequality within and among countries</p>
        </div>
        <div className="col slider" style={{ background: `url(${energy})` }} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={makeWidthShorter}>
          <span className="tag">SDG 7</span>
          <h6>Affordable and clean energy</h6>
          <p>Ensure access to affordable, reliable, sustainable and modern energy for all</p>
        </div>
        <div className="col slider" style={{ background: `url(${water})` }} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={makeWidthShorter}>
          <span className="tag">SDG 6</span>
          <h6>Life Below Water</h6>
          <p>Conserve and sustainably use the oceans, seas and marine resources for sustainable development</p>
        </div>
        <div className={`col slider ${isMobile ? '' : 'active'}`} style={{ background: `url(${growth})` }} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={makeWidthShorter}>
          <span className="tag">SDG 8</span>
          <h6>Decent work and economic growth</h6>
          <p>Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all</p>
        </div>
        <div className="col slider" style={{ background: `url(${consumption})` }} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={makeWidthShorter}>
          <span className="tag">SDG 12</span>
          <h6>Responsible consumption</h6>
          <p>Ensure sustainable consumption and production patterns</p>
        </div>
        <div className="col slider" style={{ background: `url(${climate})` }} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={makeWidthShorter}>
          <span className="tag">SDG 13</span>
          <h6>Climate action</h6>
          <p>Take urgent action to combat climate change and its impacts</p>
        </div>
        <div className="col slider" style={{ background: `url(${clean})` }} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={makeWidthShorter}>
          <span className="tag">SDG 11</span>
          <h6>Clean water and sanitation</h6>
          <p>Ensure availability and sustainable management of water and sanitation for all</p>
        </div>
        <div className="col slider" style={{ background: `url(${sustainable})` }} onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter} onClick={makeWidthShorter}>
          <span className="tag">SDG 11</span>
          <h6>Sustainable cities and communities</h6>
          <p>Make cities and human settlements inclusive, safe, resilient and sustainable</p>
        </div>

      </div>
    </div>
  </div>)
}
export default ClimateChallenges;