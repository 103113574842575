import React,{useState,useEffect} from 'react';
import './resources.scss';
import Arrow from '../../icons/arrow';
import { Accordion,Button } from '@blackboxsa/library';
import CaseStudyCards from '../case_study_cards/case_study_cards';
import TrainingCourse from '../TrainingCourses/TrainingCourses';
import WasteFractions from '../waste_fractions/waste_fractions';
import Glossary from '../glossary/Glossary'
import Collapse from '../collapse/Collapse';

const ResourceComponent = ({map_url}) => {
    const [accordionOpen, setAccordionOpen] = useState([false]);
    const [openIndex, setOpenIndex] = useState(null);

    const [accordionOpen1, setAccordionOpen1] = useState([false]);
    const [openIndex1, setOpenIndex1] = useState(null);
    const data = [
        { title: "Case studies", copy: <CaseStudyCards/>,height:5000 },
        { title: "Glossary", copy: <Glossary/>,height:5000  },
        // { title: "Training courses", copy: <TrainingCourse/>,height:5000  },
        ]

    useEffect(() => {
        const index = accordionOpen.indexOf(true);
        const index1= accordionOpen1.indexOf(true);
        setOpenIndex(index)
        setOpenIndex1(index1)
      },[accordionOpen,accordionOpen1])

  return (
    <div className='resources'>
        <div className='container'>
            <h1 className='resources--header'>Resources
            </h1>
            <p className='resources--content'>The WROSE team has provided quality resources to assist users and policy makers interested in growing the waste sector.</p>
            <Collapse data={data} chevron={<Arrow />}/>
            {/* <Accordion
                style={{ width: "100%" }}
                data={data}
                width={0}
                chevron={<Arrow />}
            /> */}
            <a style={{textDecoration:'none'}} href='/demo' target='_blank'>
                <div style={{marginBottom:"10px"}} className='source-container w-100 d-flex justify-content-between align-items-center'>
                    <p className='m-0'>Interactive demo</p>
                    <Arrow/>
                </div>
            </a>

            <a style={{textDecoration:'none'}} href={map_url} target='_blank'>
                <div className='source-container w-100 d-flex justify-content-between align-items-center'>
                    <p className='m-0'>Interactive map</p>
                    <Arrow/>
                </div>
            </a>
        </div>
    </div>
  )
}

export default ResourceComponent