import React, { useContext ,useState} from "react";
import { GlobalAttributes } from "../../context/screenSize";
import { Buttons } from "./templates/buttons";
import { Content } from "./templates/content";
import { bodies } from "./templates/content";
import { titles } from "./templates/content";
import ArrowLeft from './ArrowLeft.svg'
import './glossary.scss'
const Glossary = () => {

    const [selected, setSelected] = useState(0)

    const context = useContext(GlobalAttributes)

    const controlStyle = context.mobileView ? {
        width: "100%",
        height: "100%"
    } : {}

    const tabStyle = context.mobileView ? {
        margin:"0 0 0 0",
    } :
    {
       margin:"0 0 0 41px"
    }
    const setView = (index) => {
        setSelected(index)
        context.setGlossaryContentView(true)
    }

    return (
        <div className="glossary">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                     {context.mobileView === true && context.glossaryContent === true ? <a onClick={() => context.setGlossaryContentView(false)}><img style={{marginRight:16}} src={ArrowLeft}/>list</a> : ''}
                    </div>
                    <div className="col-12">
                        <div className="d-flex align-items-start">

                            {context.mobileView === true && context.glossaryContent === false ?
                                <Buttons controlStyle={controlStyle} setView={setView}
                                titles={titles} contentStyle={tabStyle}/> : " "}

                            {context.mobileView === true && context.glossaryContent === true ?
                                <Content contentBody={bodies} titles={titles}
                                selected={selected} contentStyle={tabStyle} mobile={context.mobileView}/> : ""}

                            {context.mobileView === false ?
                            <> <Buttons controlStyle={controlStyle} setView={setView} titles={titles} />
                            <Content contentBody={bodies} titles={titles}
                             selected={selected} contentStyle={tabStyle} mobile={context.mobileView} /> </>
                            :
                            ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Glossary;