import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={23}
    height={22}
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={11.667} cy={11} r={11} fill="#018186" />
    <path
      d="M16.3176 7.79937C16.2517 7.73298 16.1734 7.68028 16.0871 7.64432C16.0008 7.60836 15.9082 7.58984 15.8147 7.58984C15.7212 7.58984 15.6286 7.60836 15.5423 7.64432C15.456 7.68028 15.3776 7.73298 15.3118 7.79937L10.0347 13.0835L7.8176 10.8594C7.74923 10.7933 7.66852 10.7414 7.58008 10.7065C7.49164 10.6717 7.3972 10.6546 7.30216 10.6562C7.20712 10.6579 7.11333 10.6782 7.02615 10.7161C6.93896 10.754 6.8601 10.8087 6.79406 10.8771C6.72801 10.9454 6.67608 11.0262 6.64123 11.1146C6.60638 11.203 6.58928 11.2975 6.59093 11.3925C6.59257 11.4876 6.61292 11.5813 6.65081 11.6685C6.6887 11.7557 6.7434 11.8346 6.81177 11.9006L9.53177 14.6206C9.59761 14.687 9.67596 14.7397 9.76227 14.7757C9.84859 14.8116 9.94117 14.8301 10.0347 14.8301C10.1282 14.8301 10.2208 14.8116 10.3071 14.7757C10.3934 14.7397 10.4718 14.687 10.5376 14.6206L16.3176 8.84062C16.3895 8.77429 16.4469 8.69378 16.4861 8.60418C16.5254 8.51458 16.5456 8.41781 16.5456 8.31999C16.5456 8.22217 16.5254 8.12541 16.4861 8.0358C16.4469 7.9462 16.3895 7.8657 16.3176 7.79937Z"
      fill="white"
    />
  </svg>
);
export default SVGComponent;
