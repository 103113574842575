const deselect = (selected, setFractions) => {

    if (selected[0].selected === false && selected[1].selected === false && selected[2].selected === false && selected[3].selected === false && selected[4].selected === false) {
        setFractions([])
    }
    else if ((selected[0].selected === false && selected[1].selected === false && selected[2].selected === true && selected[3].selected === false && selected[4].selected === false) ||
        (selected[4].selected === true && selected[3].selected === true && selected[2].selected === true)) {
        const value = ((selected[4].selected === true || selected[3].selected === true) && selected[2].selected === true && selected[0].selected === false && selected[1].selected === false) ?
            ["Paper and cardboard", "Plastics", "Glass", "Metals", "Biogenic waste"] :
            ["Paper and cardboard", "Plastics", "Glass", "Metals"]

        setFractions(value)
    }
    else if ((selected[3].selected === true || selected[4].selected === true) && selected[0].selected === false && selected[1].selected === false && selected[2].selected === false) {
        setFractions(["Biogenic waste"])
    }
}

const select = (row, setFractions, fractions) => {
    if (row.id === 1 || row.id === 2) {
        setFractions(fractions)
    }
    else if (row.id === 3) {
        setFractions(prev => filter(prev, [fractions[0], fractions[1], fractions[2], fractions[3]]))
    }
    else if (row.id === 4 || row.id === 5) {
        setFractions(prev => filter(prev, [fractions[4]]))
    }
}

const filter = (prev, next) => {

    return [...new Set([...prev, ...next])]
}

export const handleChange = (e, index, setSelected,setFractions, selected, fractions) => {

    let checked = e.target.checked

    setSelected(
        selected.map((row) => {

            if (index === row.id) {
                row.selected = checked

                if (row.selected === false) {

                    deselect(selected, setFractions)
                }

                if (row.selected === true) {

                    select(row, setFractions, fractions)
                }
            }
            return row
        })
    )
}