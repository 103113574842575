import React from 'react';
import './functionality_table.scss';
import Check from './check'

const functionality_table = () => {
    const tableData = [
        {
            functionsality:'Full waste composition database access',
            demostration:'',
            light_weight:<Check/>,
            full_version:<Check/>
        },
        {
            functionsality:'Creating a user defined scenario',
            demostration:<Check/>,
            light_weight:<Check/>,
            full_version:<Check/>
        },
        {
            functionsality:'Access to default scenarios',
            demostration:'',
            light_weight:<Check/>,
            full_version:<Check/>
        },
        {
            functionsality:'Unrestricted scenario formation',
            demostration:'',
            light_weight:'',
            full_version:<Check/>
        },
        {
            functionsality:'Unlimited license duration',
            demostration:'',
            light_weight:'',
            full_version:<Check/>
        },
        {
            functionsality:'Importing project',
            demostration:'',
            light_weight:'',
            full_version:<Check/>
        },
        {
            functionsality:'Exporting project',
            demostration:'',
            light_weight:'',
            full_version:<Check/>
        },
        {
            functionsality:'Exporting user defined process diagram',
            demostration:'',
            light_weight:<Check/>,
            full_version:<Check/>
        },
        {
            functionsality:'Result available to all environmental, economic, social and institutional indicators',
            demostration:'',
            light_weight:<Check/>,
            full_version:<Check/>
        },
        {
            functionsality:'Saving projects',
            demostration:'',
            light_weight:<Check/>,
            full_version:<Check/>
        },
        {
            functionsality:'Printing detail reports',
            demostration:'',
            light_weight:'',
            full_version:<Check/>
        },
        {
            functionsality:'Printing summarized reports',
            demostration:'',
            light_weight:<Check/>,
            full_version:<Check/>
        },
        {
            functionsality:'Printing graphs',
            demostration:<Check/>,
            light_weight:<Check/>,
            full_version:<Check/>
        },
        {
            functionsality:'User manual access',
            demostration:<Check/>,
            light_weight:<Check/>,
            full_version:<Check/>
        },
        {
            functionsality:'Training will provide',
            demostration:'',
            light_weight:'',
            full_version:<Check/>
        },


    ]
  return (
    <table className='w-100'>
  <tr>
        <th>Functionality</th>
        <th className='d-none d-md-table-cell'>Demostration</th>
        <th className='d-none d-md-table-cell'><nobr>Light Version</nobr></th>
        <th className='d-none d-md-table-cell'><nobr>Full Version</nobr></th>
        <th className='d-md-none'>Demo</th>
        <th className='d-md-none'><nobr>Light</nobr></th>
        <th className='d-md-none'><nobr>Full</nobr></th>
  </tr>
  {tableData.map((content,i) => {
      return(
        <tr key={i}>
        <td className=''>{content.functionsality}</td>
        <td className='content'>{content.demostration}</td>
        <td className='content'>{content.light_weight}</td>
        <td className='content'>{content.full_version}</td>
      </tr>
      )
  })}

</table>
  )
}

export default functionality_table