import React from 'react';
import Slider from '../slider/slider'
import './challanges.scss'
import Arrow from '../../icons/right-arrow'
const challanges = () => {
  return (
    <div className='challanges'>
        <div className='slider-container'><Slider/></div>
        <div className='buttons'><a href='/about'>How the model can help<Arrow fill='#FFFFFF' h={12} w={7}/></a></div>
    </div>
  )
}

export default challanges