import React,{useContext} from "react";
import { GlobalAttributes } from "../../context/screenSize";
import './footer.scss'

const Footer = ()=>{
    const { showBanner } = useContext(GlobalAttributes)
    var currentYear = new Date().getFullYear();
   return (
    <>
        <footer>
            <div className="container d-flex justify-content-center">
                <span className="text-right">© {currentYear} WROSE - <a onClick={() => showBanner(true)}>Cookie Settings</a> - <a style={{color:'#6F7274', textDecoration:'none'}} href="https://black-box.io" target="_blank">A Product by Black Box</a></span>
            </div>
        </footer>
        {/* <div className="extended-footer d-none d-md-block">

        </div> */}
    </>

    )
}
export default Footer;