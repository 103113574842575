export const Buttons = ( { controlStyle, setView, titles } ) => {

    return <div className="nav col-4 nav-pills control-height" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={controlStyle}>

    { titles.map(
        (title, index) => {
            return <button className="nav-link" key={index.toString()} onClick={ () => setView(index) }>{title}</button>
        }
    )}
</div>
}