import React from 'react';
import './waste_fractions.scss'
import Recycle from './recycle';
import Residule from './residule_waste';
import Biogenic from './biogenic_fraction'

const WasteFractions = () => {
    const data = [
        {
            header:'Recyclable fraction',
            body:'Waste material that can be reused for the manufacturing of new products. All recyclables are separated from the main waste stream and sent to waste processing xx. Recyclabes generally include paper, cardboard, plastic, glass, metals, Aluminium cans.',
            icon:<Recycle/>
        },
        {
            header:'Residule waste',
            body:'The residual fraction of waste, post treatment, is landfilled with the inclusion of landfill gas recovery for the generation of electricity.',
            icon: <Residule/>
        },
        {
            header:'Biogenic fraction',
            body:'The biogenic waste fraction includes organic/biodegradable waste (OFMSW, Food waste, garden refuse etc) that can be aerobically composted or anaerobically digested.',
            icon:<Biogenic/>
        }
    ]
  return (
    <div className='waste_fractions'>
        {data.map((content,i) => {
            return(
                <div key={i} className='d-md-flex align-items-center justify-content-center'>
                    <div className='waste_fractions--container d-flex d-md-block'>
                        <div className='circle'>{content.icon}</div>
                        <h1 className='d-md-none'>{content.header}</h1>
                    </div>
                    <div className='waste_fractions--content'>
                        <h1 className='d-none d-md-block'>{content.header}</h1>
                        <p>{content.body}</p>
                    </div>
            </div>
            )
        })}

    </div>
  )
}

export default WasteFractions