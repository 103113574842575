import React, { useState } from 'react';
import './cards_container.scss'
import Card from '../card/card'


const CardsContainer = () => {

    const [card,setCard] = useState([
        {
            buttonText:'About Us',
            background: 'about-us',
            description:'The Waste Resource Optimisation and Scenario Evaluation (WROSE) decision-making tool was developed by the SARCHI Chair in Waste and Climate Change, using various studies conducted at the University of KwaZulu-Natal since 2010. ',
            href:'about_us',
            open:false,
        },
        {
            buttonText:'Resources',
            background: 'resources',
            description:'The WROSE team has provided quality resources to assist users and policy makers interested in growing the waste sector. ',
            href:'resource',
            open:false,
        }
    ])

    const [card1,setCard1] = useState([
        {
            buttonText:'Case studies',
            background: 'case-studies',
            description:"Read through various case studies of how WROSE can be applied to various scenarios, and go through some of our workshop material. Download our PDF's for a full text copy.",
            href:'resource',
            open:false,
        },
        {
            buttonText:'Benefits',
            background: 'benefits',
            description:'The WROSE model aims to aid the waste managers in determining the most appropriate waste management strategy / scenario / technology that responds to all four levels of sustainability. WROSE is uniquely suited to waste management in that it has the following benefits...',
            href:'about_us',
            open:false,
        }
    ])

    const handleClick = (index) => {
        const updatedCards1 = card1.map((item, i) => {
            return {
              ...item,
              open: false,
            };
          });
          setCard1(updatedCards1);

        const updatedCards = card.map((item, i) => {
          if (i === index) {
            // Toggle the 'open' property of the clicked card
            return {
              ...item,
              open: !item.open,
            };
          }
          // Set 'open' to false for other cards
          return {
            ...item,
            open: false,
          };
        });
        setCard(updatedCards);
      };

      const handleClick1 = (index) => {
        const updatedCards1 = card.map((item, i) => {
            return {
              ...item,
              open: false,
            };
          });
          setCard(updatedCards1);
        const updatedCards = card1.map((item, i) => {
          if (i === index) {
            // Toggle the 'open' property of the clicked card
            return {
              ...item,
              open: !item.open,
            };
          }
          // Set 'open' to false for other cards
          return {
            ...item,
            open: false,
          };
        });
        setCard1(updatedCards);
      };

  return (
    <div className='cards_container'>
       <div className='container'>
            <div className='cards'>
                <div className='d-block d-md-flex'>
                {card.map((item,i) => {
                    return (
                        <Card
                        buttonText={item.buttonText}
                        background={item.background}
                        description={item.description}
                        href={item.href}
                        open={item.open}
                        onClick={() => handleClick(i)} // Pass the index to the handleClick function
                      />

                    )
                    })}
                </div>
                <div id='slide-cards' className='d-block d-md-flex'>
                {card1.map((item, i) => {
              return (
                <Card
                  buttonText={item.buttonText}
                  background={item.background}
                  description={item.description}
                  href={item.href}
                  open={item.open}
                  onClick={() => handleClick1(i)} // Pass the index to the handleClick function
                />
              );
            })}
                </div>
            </div>

        </div>
    </div>
  )
}

export default CardsContainer