import React from 'react'
import './change_makers.scss'
import Arrow from '../../icons/right-arrow'
const change_makers = (props) => {
  return (
    <>
        <div style={{marginTop:props.margin}} className={`change_makers ${props.class}`}>
        <div className='background'>
            <div className='left-shade'>
                <div className='full-shade'>
                <div className='container'>
                        <h1>Become a part of our<br/>
                            network of change-makers!</h1>
                        <p>Contact us or become a collaborator! We’re not just looking<br className='d-none d-md-block'/>
                            for people to use WROSE but for people who believe in the<br className='d-none d-md-block'/>
                            model and want to see it grow. </p>
                        <a href='/contact_us'>Contact us<Arrow fill='#FFFFFF' h={12} w={7}/></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='change_makers--spacer'>

    </div>
    </>
  )
}

export default change_makers