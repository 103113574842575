import React,{useEffect, useState,useRef} from 'react';
import './about_us.scss'
import { Accordion } from '@blackboxsa/library';
import ModelFor from '../model_for/model_for';
import FundersAndPartners from '../funders_and_partners/funders_and_partners';
import WroseTeam from '../wrose_team/wrose_team';
import { model_for_data,the_benefits } from '../../data/data'
//icons
import Government from './government.svg';
import Planners from './planners.svg';
import Research from './research.svg';
import Bin from './bin.svg';
import Arrow from '../../icons/arrow'
import Calc from './calculator-alt.svg'
import Speed from './speedometre.svg'
import Shield from './shield-check.svg'
import { useLocation } from "react-router-dom";
import Collapse from '../collapse/Collapse';

const About_us = ({scroll}) => {
      const sectionRef = useRef(null);
      const { pathname } = useLocation();
      console.log('scroll',scroll)
    useEffect(() => {
      if (sectionRef.current && scroll) {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      else {
        window.scrollTo({top:0,left:0,behaviour:"instant"});
      }
    }, [pathname,sectionRef]);

  const [accordionOpen, setAccordionOpen] = useState([false, false, false, false]);
  const [openIndex, setOpenIndex] = useState(null);
    const data =                 [
        { title: "Who is the model for?", copy: <ModelFor data={model_for_data} icons ={[Government,Planners,Research,Bin]}/>,height:2400 },
        { title: "The Benefits", copy: <ModelFor data={the_benefits} icons ={[,Calc,Speed,Shield]}/>,height:2400 },
        { title: "Funders, partners and endorsements", copy: <FundersAndPartners/>,height:2400 },
        { title: "The WROSE team", copy: <WroseTeam/>,height:2400 },
    ]

    useEffect(() => {
      const index = accordionOpen.indexOf(true);
      setOpenIndex(index)
    },[accordionOpen])
  return (
    <div className='about_us'>
        <div className='container'>
            <h1 className='about_us--header'>About us</h1>
             <h5 className='about_us--small_header'>Starting from a place of trying to reduce waste, the WROSE model seeks to make the world a better place.</h5>
            <p className='about_us--paragraph'>The Waste Resource Optimisation and Scenario Evaluation (WROSE) decision-making tool was developed by the SARCHI Chair in Waste and Climate Change, using various studies conducted at the University of KwaZulu-Natal since 2010.</p>
            <div  ref={sectionRef}> <p className='about_us--paragraph about_us--paragraph-2'>WROSE is a zero-waste, waste and GHG emission reduction model which falls into the category of sustainability/environmental technology assessment tool. The model assists South African municipalities and the private sector in the development of IWMP’s and the aim of achieving zero waste targets and applying appropriate waste strategies.</p>
            <Collapse data={data} chevron={<Arrow />}/>
            {/* <Accordion
              style={{ width: "100%"}}
              data={data}
              width={0}
              chevron={<Arrow />}
            /> */}
              <a style={{textDecoration:'none'}} href='/publications_and_datasources'>
                <div className='source-container w-100 d-flex justify-content-between align-items-center'>
                    <p className='m-0'>Publications and data sources</p>
                    <Arrow/>
                </div>
              </a></div>
        </div>
    </div>
  )
}

export default About_us