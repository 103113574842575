import React, { useState } from 'react';
import './card.scss';
import Close from './Modal close X.svg';
import Arrow from '../../icons/right-arrow';

const Card = ({ buttonText, background, description, href, open, onClick }) => {

  const [hovered, setHovered] = useState(false)

  const handleHover = () => {
    setHovered(!hovered)
  }

  return (
    <>
      <div onClick={onClick} className={`d-flex d-md-none card-animation ${background} ${background} ${open ? 'open' : ''}`}>
        <button onClick={onClick}>{buttonText}</button>
        <div
          className={`slide ${open ? 'open' : ''}`}
          onClick={onClick}
        >
          <div className="content">
            <div className='close' onClick={onClick}><img src={Close} /></div>
            <div className='content--container'>
              <p>{description}</p>
              <a href={`/${href}`}>Read more <Arrow fill='#05595C' h={12} w={7} /></a>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`d-none d-md-flex card-animation ${background} ${hovered ? 'open' : ''}`}
        onMouseEnter={handleHover}
        onMouseLeave={handleHover}
      >
        <button>{buttonText}</button>
        <div className={`slide ${hovered ? 'open' : ''}`}>
          <div className="content">
            <a className="close" onClick={onClick}>
              <img src={Close} alt="Close" />
            </a>
            <div className="content--container">
              <p>{description}</p>
              <a href={`/${href}`}>
                Read more <Arrow fill="#05595C" h={12} w={7} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
