import './navbar.scss';
import logo from './wrose_logo.svg'; // with import
import logo_mobile from './logo-mobile.svg'
import burger from './burger.svg'
import React, { useState, useEffect, useContext } from 'react';
import { Container, Collapse, NavbarBrand, Nav, NavItem, NavLink, Media, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import Arrow from './arrow_donw.svg'
import ArrowWhite from './arrow-white.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import { GlobalAttributes } from "../../context/screenSize";

// Remove default html page loader
function removeLoader() {
  $('.spinner--container').remove();
}


const Navbar = (props) => {
  const { setNavBg, navBg } = useContext(GlobalAttributes)
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [mobile, setMobile] = useState(false);
  const [classes] = useState('');
  const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [resourcesDropdownOpen, setResourceDropdownOpen] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();
  const [grey, setGrey] = useState(false);

  const changeNavBg = () => {
    if (location.pathname === '/case_study') {
      setNavBg(true)
      setGrey(true)

    } else {
      window.scrollY >= 50 ? setNavBg(true) : setNavBg(false);
      setGrey(false)
    }

  }

  useEffect(() => {
    window.addEventListener('scroll', changeNavBg);
    return () => {
      window.removeEventListener('scroll', changeNavBg);
    }
  }, [navBg])

  const toggleDropdown = () => {
    setAboutDropdownOpen(!aboutDropdownOpen);
  };
  const toggle1Dropdown = () => {
    setResourceDropdownOpen(!resourcesDropdownOpen);
  };


  useEffect(() => {
    deviceCheck(480);
    removeLoader();
  })

  function deviceCheck(deviceWidth) {
    window.screen.width <= deviceWidth ?
      setMobile(true)
      :
      setMobile(false)
  }

  useEffect(() => {
    setTimeout(() => {
      if (window.location.hash) {
        const element = document.getElementById(window.location.hash.replace('#', ''));
        const y = element.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 100);
  }, [window.location.hash])

  return (
    <>
      <div className={`navigation + ${props.className} ${classes}`} style={navBg ? { backgroundColor: grey && mobile ? '#202124' : '#102F39' } : { backgroundColor: 'transparent' }}> {/* Use bg_ and color classes in helpers.scss eg 'bg_transparent' */}
        <Container fluid={true}>
          <nav className="navbar navbar-expand-lg header-navigation"> {/* Used default bootstrap markup to avoid using reactstrap default classes */}
            <Link to='/' id="black-box-logo" className='d-md-none d-block'>
              <Media object src={mobile ? logo_mobile : logo} alt="Generic placeholder image" />
            </Link>
            <Collapse isOpen={isOpen} navbar>
              <NavbarBrand className='mobile-nav d-none d-md-block' href='/' id="black-box-logo">
                <Media object src={mobile ? logo_mobile : logo} alt="Generic placeholder image" />
              </NavbarBrand>
              <Nav id='myDiv' navbar>

                {mobile ?

                  <div className='dropdown-container'>
                    <Dropdown nav isOpen={aboutDropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle nav>
                        About <img style={{ marginLeft: 9 }} src={Arrow} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => { navigate('/about'); toggle() }}>Who is the model for?</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/about'); toggle() }}>Benefits</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/about'); toggle() }}>Funders, partners and endorsements</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/about'); toggle() }}>The WROSE team</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/publications_and_datasources'); toggle() }}>Publications and data sources</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Dropdown nav isOpen={resourcesDropdownOpen} toggle={toggle1Dropdown}>
                      <DropdownToggle nav >
                        Resources <img style={{ marginLeft: 9 }} src={Arrow} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => { navigate('/resources'); toggle() }} style={{ boxShadow: 'none' }}>Case studies</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/resources'); toggle() }} style={{ boxShadow: 'none' }}>Glossary</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/demo'); toggle() }} style={{ boxShadow: 'none' }}>Interactive Demo</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <NavItem>
                      <NavLink href={props.map_url === '' ? 'https://map.wrose.co.za/': props.map_url} target='_blank'>Interactive Map</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/contact_us">Contact Us</NavLink>
                    </NavItem>
                    <NavItem className='d-none d-md-block'>
                      <NavLink className='btn cta' href="/app_holding">WROSE App</NavLink>
                    </NavItem>
                    <a className='d-block d-md-none wrose-app' href='/app_holding'>WROSE App</a>
                  </div>
                  :
                  <div className='d-flex'>
                    <Dropdown nav isOpen={aboutDropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle nav >
                        About <img style={{ marginLeft: 9 }} src={ArrowWhite} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => { navigate('/about_us'); }}>About Us</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/about'); }}>Who is the model for?</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/about'); }}>Benefits</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/about'); }}>Funders, partners and endorsements</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/about'); }}>The WROSE team</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/publications_and_datasources'); }}>Publications and data sources</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Dropdown nav isOpen={resourcesDropdownOpen} toggle={toggle1Dropdown}>
                      <DropdownToggle nav >
                        Resources <img style={{ marginLeft: 9 }} src={ArrowWhite} />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => { navigate('/resources'); }} style={{ boxShadow: 'none' }}>Case studies</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/resources'); }} style={{ boxShadow: 'none' }}>Glossary</DropdownItem>
                        <DropdownItem onClick={() => { navigate('/demo'); }} style={{ boxShadow: 'none' }}>Interactive Demo</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <NavItem>
                      <NavLink href="https://map.wrose.co.za" target='_blank'>Interactive Map</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/contact_us">Contact Us</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className='btn cta' href="/app_holding">WROSE App</NavLink>
                    </NavItem>
                  </div>
                }

              </Nav>
            </Collapse>
            <button aria-label="Toggle navigation" id="hamburger" type="button" className="navbar-toggler" onClick={toggle}>
              <Media object src={burger} alt="Generic placeholder image" />
            </button>
          </nav>
        </Container>

      </div>
      {/* <div className='d-block d-md-none w-100' style={{height:56}}></div> */}
    </>
  );
}

export default Navbar;