import React, { useState } from 'react'
import './collapse.scss';
const Collapse = ({data,chevron}) => {
    const [clicked,setClicked] = useState(null)

    const getClicked = (index) => {
        if(index === clicked) {
          setClicked(null)
        }
        else {
          setClicked(index)
        }

    }
    return (
      <>
      {data.map((item,index) => {
        return(
          <div className="w-100">
            <div className="panel-group mt-0">
              <div className="panel panel-default">
                <div onClick={()=> {getClicked(index)}} className={clicked === index ? "panel-heading open" : "panel-heading "} >
                  <h4 className="panel-title m-0" style={clicked === index ? {color:'#202124'}:{color:'white'}} >{item.title}</h4>
                  {chevron}
                </div>
                <div className={clicked === index ? "panel-collapse": "panel-collapse panel-close"} style={clicked === index ? {maxHeight:item.height}: {maxHeight:0}}>
                  <div className="panel-body">{item.copy}</div>
                </div>
              </div>
            </div>
        </div>
        )
      })

      }
      </>

      );
}

export default Collapse