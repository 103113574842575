import { createContext, useState, useEffect } from "react";

const useWindowSize = () => {
  const [size, setSize] = useState([
    window.innerHeight,
    window.innerWidth,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth]);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
};

export const GlobalAttributes = createContext();

export const AttributeProvider = ({ children }) => {
  // Retrieve stored values from localStorage (or use the default values)
  const storedToggleState = localStorage.getItem("toggleState");
  const storedShowPrivacyModal = localStorage.getItem("showPrivacyModal");
  const storedShowBanner = localStorage.getItem("showBanner");

  const [mobileView, setMobileView] = useState(false);
  const [glossaryContent, setGlossaryContentView] = useState(false);
  const [caseStudyContent, setCaseStudyContent] = useState({});
  const [navBg, setNavBg] = useState(false);
  const [height, width] = useWindowSize();

  const [toggleState, setToggleState] = useState(
    storedToggleState ? JSON.parse(storedToggleState) : true
  );
  const [showPrivacyModal, showModal] = useState(
    storedShowPrivacyModal ? JSON.parse(storedShowPrivacyModal) : false
  );
  const [show, showBanner] = useState(
    storedShowBanner ? JSON.parse(storedShowBanner) : true
  );

  useEffect(() => {
    // Update localStorage when toggleState changes
    localStorage.setItem("toggleState", JSON.stringify(toggleState));
  }, [toggleState]);

  useEffect(() => {
    // Update localStorage when showPrivacyModal changes
    localStorage.setItem("showPrivacyModal", JSON.stringify(showPrivacyModal));
  }, [showPrivacyModal]);

  useEffect(() => {
    // Update localStorage when show changes
    localStorage.setItem("showBanner", JSON.stringify(show));
  }, [show]);

  if (width <= 768 && mobileView === false) {
    setMobileView(true);
  }
  if (width > 788 && mobileView === true) {
    setMobileView(false);
  }

  return (
    <GlobalAttributes.Provider
      value={{
        height,
        width,
        mobileView,
        glossaryContent,
        caseStudyContent,
        setMobileView,
        setGlossaryContentView,
        setCaseStudyContent,
        navBg,
        setNavBg,
        toggleState,
        setToggleState,
        showPrivacyModal,
        showModal,
        show,
        showBanner,
      }}
    >
      {children}
    </GlobalAttributes.Provider>
  );
};
