import React, { useState } from 'react';
import DownArrow from '../../icons/DownArrow'
import './wrose_team.scss';
import Cristina from './Cristina.png';
import Nordine from './Noredine.png';
import Sameera from './profile image.png';
import Yared from './Yared.png';
import Andrea from './Andrea.png';
import Antonio from './Antonia.png';
import Surabhi from './Surabhi.png';
import Fathima from './Fathima.png'

const WroseTeam = () => {
    const [openIndex, setOpenIndex] = useState(-1);
    const team = [
      {
        id:0,
        name: "Prof. Cristina Trois",
        about: `South African Research Chair in Waste and Climate Change (SARChI) at
        University of KwaZulu-Natal
        <br/> <br/>
        PhD, Full Professor in Environmental Engineering
        <br/> <br/>
        Specialisations: Environmental Sanitary Engineering,Waste and Resources Management, Waste and Climate Change,
        WROSE model developer, System modelling, GHG emissions mitigation, Decision support tools, Waste to Energy,
        Waste Management in Developing`,
        image: Cristina,
      },
      {
        id:1,
        name: "Dr. Noredine Mahdjoub",
        about: `PhD, Senior Research Scientist in the SARCHI Chair Waste and Climate Change
        <br/> <br/>
        Specialisations: Alternative Hybrid Materials/Urban Mining/Circular Economy/Waste and Resources Management/Waste to
        Energy/Business Development`,
        image: Nordine,
      },
      {
        id:2,
        name: "Sameera Kissoon",
        about: `PhD candidate, Environmental Scientist, Researcher in the SARCHI Chair in Waste and Climate Change
        <br/> <br/>
        Specialisations: Waste and resources management, WROSE, GHG emissions modelling, Social and Institutional Indicators,
        Integrated Waste Management Plans, Waste Governance`,
        image: Sameera,
      },
      {
        id:3,
        name: "Yared Abera",
        about: `PhD candidate, Geomatic Engineer, Researcher in the SARCHI Chair in Waste and Climate Change.
        Specialisations: WROSE development, System modelling, Software development, GHG emission factors`,
        image: Yared,
      },
      {
        id:4,
        name: `Andrea Dell'Orto`,
        about: `PhD candidate, Environmental Engineer, Researcher and Grant Manager in the SARCHI
        Chair Waste and Climate Change. Specialisations: Environmental Engineering,
        Hydrogen production from waste, Double Stage Anaerobic Digestion, Waste to Energy, Project Management`,
        image: Andrea,
      },
      {
        id:5,
        name: "Dr. Antonio Blanco Montero",
        about: `PhD, Architect, Senior Research Scientist in the SARCHI Chair in Waste
        and Climate Change and Coordinator of the Umgeni Resilience Project (Component 2).
        Specialisations: Waste Architecture, Sustainable Cities, Climate Change Resilient Infrastructure`,
        image: Antonio,
      },
      {
        id:6,
        name: "Dr. Surabhi Srivastava",
        about: `PhD (Civil Engineering), Environmental Scientist, Master in Civil Engineering,
        Postdoctoral Researcher in the SARCHi Chair in Waste and Climate Change. Specialisations: WASH (Water, Sanitation, Hygiene),
        Waste Water Treatment Plant Modelling/Design,
        FOG management (Fat, Oil and Grease), GreenUKZN, Waste Management in Universities`,
        image: Surabhi,
      },
      {
        id:7,
        name: "Fathima Ali",
        about: `PhD candidate, Chemist and Master in Environmental Engineering, Senior Laboratory Technician in the
        Environmental Engineering Laboratory at UKZN, Researcher in the SARCHI Chair in Waste and Climate Change. Specialisations:
        Analytical Chemistry, Environmental Engineering,
        Reactor technology, Leachate treatment, Waste Treatment, Plastics in rivers and oceans.`,
        image: Fathima,
      },
    ];
    const handleItemClick = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
      };

      return (
        team.map((person, i) => {
          const isOpen = openIndex === i;
          const itemClassName = `wrose-team ${isOpen ? 'open' : ''}`;

          return (
            <div className={itemClassName} key={i}>
              <div className='header justify-content-between d-flex align-items-center w-100' onClick={() => handleItemClick(i)}>
                <div className='justify-content-between d-flex align-items-center'>
                  <div className='img-container'><img src={person.image} alt='profile' /></div>
                  <p className='m-0'>{person.name}</p>
                </div>
                <DownArrow />
              </div>

                <div className={isOpen ? "about": "about about-close"}>
                  <p dangerouslySetInnerHTML={{ __html: person.about }}></p>
                </div>
            </div>
          );
        })
      );
}

export default WroseTeam;