import React from "react";
import Hero from "../components/hero/hero";
import FundersPartnersEndorsements from "../components/about/FundersPartnersEndorsements";
import Model from "../components/about/Model";
import '../components/about/About.scss'
import Team from "../components/about/Team";
import About_us from "../components/about_us/about_us";
import ChangeMakers from "../components/change_makers/change_makers";

const About = ({scroll})=>{
    return(
        <div className="about">
            <Hero class='about-banner' />
            <About_us scroll={scroll}/>
            <ChangeMakers margin={0}/>
            {/* <FundersPartnersEndorsements />
            <Model />
            <Team /> */}
        </div>
    )
}
export default About;