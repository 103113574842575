import React from "react";
import CaseStudies from "../components/CaseStudyContent/CaseStudyContent";
import ChangeMakers from "../components/change_makers/change_makers";
const CaseStudy = ()=>{
    return(
        <div className="casestudy">
            <CaseStudies />
            <ChangeMakers class='case_study-template' margin={0}/>
        </div>
    )
}
export default CaseStudy;