import React from 'react';
import './interactive_showcase.scss';
import Image from './interactive_map.png'
import Arrow from '../../icons/right-arrow'

const interactive_showcase = ({map_url}) => {
  return (
    <div id='i_s' className='interactive_showcase'>
         <div className='container'>
            <div className='d-md-flex'>
                <div className='image-container'><img src={Image}/></div>
                <div className='right-container'>
                    <div>
                        <h1>Explore the power of our simulations with real data</h1>
                        <p>We’ve applied our simulations to the 8 primary metros of South Africa for you to test. See the effect of the WROSE scenarios on a metro near you.</p>
                        <a href={map_url === '' ? 'https://map.wrose.co.za/': map_url} target='_blank'>View the WROSE interactive map <Arrow fill='#FFFFFF' h={12} w={7}/></a>
                    </div>
                </div>
            </div>
         </div>
    </div>
  )
}

export default interactive_showcase