import React, { useState } from "react";
import { fractions } from "./data/data";
import { handleChange } from "./helper_functions/helpers";
import './select-waste.scss';

export const Select = ({ selected, setSelected, setFilters }) => {
    const [fractionsSelected, setFractions] = useState([])

    return (
        <div className="select-waste">
            <div className="technology">
                <div className="header" onClick={() => setFilters(false)}>
                    <h6>Select waste technology</h6>
                </div>
                <div className="checkboxes">

                    {selected.map((item) => {

                        return <label htmlFor={item.technology} key={item.id.toString()} >

                            <input type="checkbox"
                                name={item.technology}
                                id={item.technology}
                                onChange={(e) => { handleChange(e, item.id, setSelected,setFractions, selected, fractions) }}
                                checked={item.selected}
                            />

                            {item.technology}
                        </label>
                    })}
                </div>
            </div>
            <div className="fraction">
                <div className="header">
                    <h6>Waste fraction Included</h6>
                </div>
                <ul>
                    {
                        fractionsSelected.map((fraction, index) => {

                            return <li key={index.toString()}>{fraction}</li>
                        })
                    }
                </ul>
            </div>
        </div>
    )
}