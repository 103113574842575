import React from "react";
import Hero from "../components/hero/hero";
import '../components/about/About.scss'
import PublicationsAndDataSourcesComponent from "../components/publications_and_datasources/publications_and_datasources";
import ChangeMakers from "../components/change_makers/change_makers";

const PublicationsAndDataSources = ()=>{
    return(
        <div className="about">
            <Hero class='publication-banner' />
            <PublicationsAndDataSourcesComponent/>
            <ChangeMakers margin={-10}/>
        </div>
    )
}
export default PublicationsAndDataSources;