import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={22}
    height={12}
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8873 11.4978L20.7008 2.66869C20.847 2.52368 20.9631 2.35115 21.0423 2.16106C21.1215 1.97097 21.1622 1.76708 21.1622 1.56115C21.1622 1.35522 21.1215 1.15133 21.0423 0.961244C20.9631 0.771154 20.847 0.598626 20.7008 0.453612C20.4085 0.163076 20.0132 -5.02267e-08 19.6011 -6.82405e-08C19.189 -8.62543e-08 18.7936 0.163076 18.5013 0.453611L10.7018 8.17519L2.98018 0.453611C2.68791 0.163075 2.29254 -8.2482e-07 1.88044 -8.42834e-07C1.46833 -8.60848e-07 1.07297 0.163075 0.780697 0.453611C0.633303 0.598082 0.516045 0.770367 0.435713 0.960482C0.355383 1.1506 0.31358 1.35476 0.312723 1.56115C0.313579 1.76754 0.355383 1.9717 0.435713 2.16182C0.516045 2.35193 0.633303 2.52422 0.780697 2.66869L9.59421 11.4978C9.74029 11.6562 9.91758 11.7825 10.1149 11.8689C10.3122 11.9554 10.5253 12 10.7408 12C10.9562 12 11.1693 11.9554 11.3666 11.8689C11.5639 11.7825 11.7412 11.6562 11.8873 11.4978Z"
      fill="#018186"
    />
  </svg>
);
export default SVGComponent;