import React from 'react'
import './sustainability_and_impact.scss'
import Arrow from '../../icons/right-arrow'

const sustainability_and_impact = () => {
  return (
    <div className='sustainability_and_impact'>
        <div className='container'>
            <h1>Sustainability and impact<br/>assessment at your fingertips.</h1>
            <p>The model defines best waste management scenarios based on various sustainability<br/>indicators:
                environmental, economic, social and institutional</p>
            <a href='/demo'>View the interactive demo <Arrow fill='#3C4043' h={12} w={7}/></a>
        </div>
    </div>
  )
}

export default sustainability_and_impact