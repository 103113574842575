import * as React from "react";
const SVGComponent = (props) => (

  <svg
    style={{marginLeft:12}}
    width={props.w}
    height={props.h}
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.31811 5.40796L1.60144 0.699624C1.52397 0.621517 1.43181 0.559522 1.33026 0.517214C1.22871 0.474907 1.11979 0.453125 1.00978 0.453125C0.899765 0.453125 0.790844 0.474907 0.689294 0.517214C0.587745 0.559522 0.495578 0.621517 0.418108 0.699624C0.262899 0.855759 0.175781 1.06697 0.175781 1.28712C0.175781 1.50728 0.262899 1.71849 0.418108 1.87462L4.54311 6.04129L0.418108 10.1663C0.262899 10.3224 0.175781 10.5336 0.175781 10.7538C0.175781 10.9739 0.262899 11.1852 0.418108 11.3413C0.495287 11.42 0.587325 11.4827 0.688888 11.5256C0.79045 11.5685 0.899519 11.5908 1.00978 11.5913C1.12003 11.5908 1.2291 11.5685 1.33066 11.5256C1.43223 11.4827 1.52426 11.42 1.60144 11.3413L6.31811 6.63296C6.4027 6.55492 6.4702 6.46021 6.51638 6.35479C6.56255 6.24938 6.58639 6.13554 6.58639 6.02046C6.58639 5.90537 6.56255 5.79153 6.51638 5.68612C6.4702 5.5807 6.4027 5.48599 6.31811 5.40796Z"
      fill={props.fill}
    />
  </svg>
);
export default SVGComponent;
