import React, {useState,useEffect, useContext} from 'react';
import { GlobalAttributes } from "../../context/screenSize";
import Privacy from './privacy.js'
import './cookies.css'
import './button.css'

const Cookies = (props) => {
    const { show,showPrivacyModal } = useContext(GlobalAttributes);
    const { showBanner } = useContext(GlobalAttributes)
    const { showModal } = useContext(GlobalAttributes)

    useEffect(() => {
      if(showPrivacyModal) {
        document.body.style = 'overflow: hidden'
      }
      else {
        document.body.style = 'overflow: unset'
      }
    },[showPrivacyModal])

    return (
      <>
        <Privacy />
        {show &&

          <div className="cookies show-cookie-container" >
            <div className="">
              <div className="row">
                <div className="col-x-lg-9 col-md-7 col-12">
                  <h1>Privacy</h1>
                  <p>To provide you with the best possible user experience, this website uses cookies and similar technologies to help personalise content and tailor ads. By clicking OK you agree to this.</p>
                </div>
                <div className="col-x-lg-3 col-md-5 col-12 text-center cookies--button_container justify-content-end align-items-center">
                  {/* <button className="mybtn mybtn-primary-transparent" onClick={event => {showModal(true)}}>Cookie Settings</button> */}
                  <button  className="mybtn mybtn-primary mb-0" onClick={event => {showBanner(false)}}>OK</button>
                </div>
              </div>
            </div>
          </div> }
      </>
    );
  };

  export default Cookies;