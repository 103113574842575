import React, { useEffect, useRef } from 'react';
import Hero from "../components/hero/hero";
import '../components/about/About.scss'
import ResourceComponent from "../components/resources/resources";
import ChangeMakers from "../components/change_makers/change_makers";
import { useLocation } from "react-router-dom";
const Resources = ({scroll,map_url})=>{

    const sectionRef = useRef(null);
    const { pathname } = useLocation();
    useEffect(() => {
      if (sectionRef.current && scroll) {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      else {
        window.scrollTo({top:0,left:0,behaviour:"smooth"});
      }
    }, []);

    return(
        <div className="about">
            <Hero class='publication-banner' />
            <div  ref={sectionRef}><ResourceComponent map_url={map_url}/></div>
            <ChangeMakers margin={-10}/>
        </div>
    )
}
export default Resources;