import React from 'react';
import './funders_and_partners.scss';
import TechInnovation from './tech innovation.png';
import Sarchi from  './SARChI-Chair-Logo-768x224 1.png';
import UkznInqubate from './ukzn inqubate.png';
import ScienceAndInnovation from './Approved-Science-and-Innovation-DSI_page-0001 2.png';
import Forestry from './forestry.png';
import Sanedi from './sanedi.png';
import Nrf from './nrf.png';
import Csir from './csir.png';
import Iwwg from './iwwg.png';
import Dsw from './dsw.png';
import GardenRoute from './garden route.png';
import TheWorldBank from './the world bank.png';
import IEA from './IEA_Bionergy_logo 1.png';
import Sabia from './SABIA-Logo-H-rev-3-1024x472-1 1.png';
import Aquila from './Quila.png';
import JiveMedia from './Jive Media.png';

const FundersAndPartners  = () => {

    const logos = [
        { link: "https://www.tia.org.za/", logo: TechInnovation },
        { link: "https://sarchiwasteandclimate.ukzn.ac.za", logo: Sarchi },
        { link: "https://inqubate.ukzn.ac.za", logo: UkznInqubate },
        { link: "https://www.dst.gov.za", logo: ScienceAndInnovation },
        { link: "https://www.dffe.gov.za", logo: Forestry },
        { link: "https://sanedi.org.za", logo: Sanedi },
        { link: "https://www.nrf.ac.za", logo: Nrf },
        { link: "https://www.csir.co.za", logo: Csir },
        { link: "https://www.tuhh.de/iue/iwwg/regional-branches/southern-africa-regional-branch-sarb.html", logo: Iwwg },
        { link: "https://www.durban.gov.za/pages/residents/cleansing-solid-waste-services", logo: Dsw },
        { link: "https://www.gardenroute.gov.za", logo: GardenRoute },
        { link: "https://www.worldbank.org/en/home", logo: TheWorldBank },
        { link: "https://task36.ieabioenergy.com", logo: IEA },
        { link: "https://sabia.org.za", logo: Sabia },
        { link: "https://www.aenv.co.za", logo: Aquila },
        { link: "https://jivemedia.co.za", logo: JiveMedia },
      ];

    const Card = ({logo}) => {
        return(<a href={logo.link} target='_blank' className='funder-card justify-content-center d-flex align-items-center'><img src={logo.logo} alt='funder logos'/></a>)
    }

  return (
    <div className='funders_and_partners'>
        {logos.map((logo,i) => {
          return <Card key={i} logo={logo}/>
        })}
    </div>
  )
}

export default FundersAndPartners