import React,{useState,useEffect} from 'react';
import './app_holding.scss';
import { Modal } from '@blackboxsa/library';
import ModalContent from '../modal_content/modal_content';

const AppHolding = () => {
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState(false);
    const [emailSending, setEmailSending] = useState(true)
    const [isSumbitAllowed, toggleIsSumbitAllowed] = useState(false)
    const [modalOpen, setModal] = useState(false)

    useEffect(()=>{

      if(email !== ''){
        toggleIsSumbitAllowed(true)
      } else {
        toggleIsSumbitAllowed(false)
      }
    },[email])

    const handleSubmit = (event) => {
        event.preventDefault();
        // Simulating a successful submission
        setModal(true)
      };

      const handleChange = (event) => {
        setEmail(event.target.value);
      };
      useEffect(() => {
        deviceCheck(480);
      })
    function deviceCheck(deviceWidth){
        window.screen.width <= deviceWidth ?
        setMobile(true)
        :
        setMobile(false)
      }
  return (
    <div className='app_holding'>
         <div className='shade'>
            <div className='container'>
                <h1>Our App is coming soon!</h1>
                <p>While you wait why don’t you join our mailing list.
                    We will keep you updated and in the loop about all things WROSE!</p>
                <form onSubmit={handleSubmit}>
                     <div className='d-md-flex'>
                        <input type='text' placeholder='Enter your email address...' value={email} onChange={handleChange} />
                        <button type='submit' disabled={!isSumbitAllowed}>{emailSending ? "Join waiting list":<> Email sent <span className='sent_icon'>{"📨"}</span></>} </button>
                     </div>
                </form>
            </div>
        </div>
        <Modal width={mobile ? '197px' : 600}  title='Thank you for signing up!' open={modalOpen} toggle={()=>{ setModal(false)}}
         insertableComponent={<ModalContent 
         email={email} 
         setModal={setModal}
         setEmailSending={setEmailSending}
         />} />
    </div>
  )
}

export default AppHolding