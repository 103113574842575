import React from 'react'
import './hero.scss'
const hero = (props) => {
  return (
    <div className={`all-hero ${props.class}`}>
        <div className='shade'>
        </div>
    </div>
  )
}

export default hero