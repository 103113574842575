import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Hero from '../hero/hero';
import './contact-us.scss'
import { Button } from '@blackboxsa/library';

export const ContactUs = () => {

    const [type, setType] = useState("Become a partner")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [number, setNumber] = useState("")
    const [description, setDescription] = useState("")
    const [formSent, sendForm] = useState(false)
    const [isSumbitAllowed, toggleIsSumbitAllowed] = useState(false)
    const inputError = useRef(null)

    useEffect(() => {
        
        const name = inputError.current.children[3]

        name.addEventListener("input", () => name.removeAttribute("data-error"))
        // inputError.current.children[0].querySelectorAll('input-container[data-error] .input').forEach(inEl => {
        //     inEl.addEventListener("input", ( ) => inEl.parentElement.removeAttribute("data-error") )
        // })
    }, [name])

    useEffect(()=>{
        if (name !== "" && email !== "" && number !== "" && description !== "") {
            toggleIsSumbitAllowed(true)
        }else{
            toggleIsSumbitAllowed(false)
        }
    },[name , email , number , description])
   
    const form = { type, name, email, number, description }

    const handleSubmit = (event) => {


        event.preventDefault();
        axios.post('/send_email', form)
            .then(() => {
                sendForm(true)
                window.scrollTo(0, 0)
            })
            .catch(error => {
                console.warn('font-end error: ', error)
            })
    }

    const handleNumbers = (char) => {
        const result = char.replace(/\D/g, '');
        setNumber(result);
    }

    return (

        <div className="">
            <Hero class='contactUs-banner' />
            <div className="contact-us">
                <div className="container">
                    <h6>Contact Us</h6>
                    {
                        formSent ?
                            <>
                                <div className='form-sent'>
                                    <h1>
                                        Thank you!
                                    </h1>
                                    <p>
                                        Your mail has been sent. We look forward to starting a converstaion <br className='d-none d-md-block' /> with you about WROSE.
                                    </p>
                                    <a href='/'>Back to home</a>
                                </div>
                            </>
                            :
                            <>
                                <p>Thank you for wanting to reach out to our team. Let us know how we can help and we will be in contact soon.</p>
                                <form onSubmit={(e) => { handleSubmit(e) }} ref={inputError}>

                                    <label htmlFor="type">Reason for mail</label>
                                    <div className='select-wrapper'>
                                        <select value={type} id="type" onChange={(e) => setType(e.target.value)} >
                                            <option>Become a partner</option>
                                            <option>Info about WROSE</option>
                                            <option>Technical assistance</option>
                                            <option>Training courses</option>
                                            <option>Other</option>
                                        </select></div>

                                    <label htmlFor="name">Name <p>*</p></label>
                                    <div className='input-container' data-error="enter your name" >
                                        <input className='input' type={"text"} id="name" placeholder={"full name"} onChange={(e) => { setName(e.target.value) }} required />
                                    </div>

                                    <label htmlFor="email">Email <p>*</p></label>
                                    <div className='input-container' data-error="enter your email" >
                                        <input className='input' type={"email"} id="email" placeholder={"example@domain.com"} onChange={(e) => { setEmail(e.target.value) }} required />
                                    </div>

                                    <label htmlFor="phone">Phone <p>*</p></label>
                                    <div className='phone-decoration input-container' data-error="enter your phone number" >
                                        <input className="input" type={"text"} id="phone" placeholder={"072 222 2222"} value={number} maxLength="10" minLength={"10"}
                                            onChange={(e) => { handleNumbers(e.target.value) }} required />
                                    </div>


                                    <label htmlFor="message">Message <p>*</p></label>
                                    <div className='input-container' data-error="enter a description" >
                                        <textarea className='input'
                                            required
                                            id='message'
                                            value={description}
                                            placeholder={"Message"}
                                            onChange={(e) => { setDescription(e.target.value) }}
                                        />
                                    </div>

                                    <Button variant='filled' text='Send message' color='black'
                                        backgroundColor='#018186'
                                        textColor={"white"}
                                        fontSize='16px'
                                        type='submit'
                                        fontWeight='400'
                                        margin='60px auto 0px auto'
                                        width={210}
                                        disabled={!isSumbitAllowed}
                                        ctaLarge={true}
                                        rounded
                                    />
                                </form>
                            </>
                    }
                </div>
            </div>
        </div>)

}