import React, { useContext } from 'react';
import './case-study-content.scss'
import { GlobalAttributes } from "../../context/screenSize";
import RightArrow from '../../icons/right-arrow';
import { useNavigate } from 'react-router-dom';
import Download from './download (2).svg'

const CaseStudy = () => {
  
  const { caseStudyContent, mobileView, setNavBg } = useContext(GlobalAttributes);
  const navigate = useNavigate()
  
  return (
    <div className='case-study-content'>
      <div className='container'>
        <div className='button-container d-md-flex justify-content-between align-items-center w-100'>
          <div className='breadcrumb d-flex align-items-center m-0'>
            <a onClick={() => { navigate('/resources'); setNavBg(false) }} >Case Studies</a><RightArrow fill='#828282' w={10} h={10} /> <span className='active'>{caseStudyContent.title}</span>
          </div>
          <a className='download' href={caseStudyContent.link || "/"} target='_blank'>
            Download pdf{' '}<img style={{ paddingLeft: 5 }} src={Download} />
          </a>
        </div>
        <h1>{caseStudyContent.title}</h1>
        <h4>{caseStudyContent.sub_title}</h4>
        <h6>Abstract</h6>
        <p dangerouslySetInnerHTML={{ __html: caseStudyContent.paragraph }}></p>

        {caseStudyContent.supportImage && <div className='img-container'>
          <img src={mobileView ? caseStudyContent.suuportImageMobile : caseStudyContent.supportImage} />
        </div>}
        <h6>Keywords</h6>
        <p>{caseStudyContent.keywords}</p>

      </div>
    </div>
  )
}

export default CaseStudy