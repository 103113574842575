import React, { useState,useContext } from 'react';
import { GlobalAttributes } from "../../context/screenSize";
import Switch from './switch';
import './privacy.css'
import './button.css'
import Close from './privacy_close.png'

const Privacy = (props) => {
    const { showPrivacyModal } = useContext(GlobalAttributes);
    const { showModal } = useContext(GlobalAttributes);
    const { showBanner } = useContext(GlobalAttributes)

    const accept = () => {
        showModal(false)
        showBanner(false)
    }

    return (
        <div>
        {
            showPrivacyModal ?
            <div className="privacy--wrapper" >
            <div className='privacy--curtain'></div>
            <div className="container privacy">
                <a className="closes"
                    onClick={event => { showModal(false) }}
                >
                    <img src={Close} alt='close x button'/>
                </a>
                <div className="">
                    <div className="col-12 privacy--overview">
                        <h5 className="privacy--title">PRIVACY OVERVIEW</h5>
                        <hr/>
                        <div className="text-box">
                            <div className=''>
                                <p>
                                    This website uses cookies to improve your experience while you navigate through the site. Cookies are categorised as mandatory (necessary) and optional. Mandatory cookies are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies, that help us analyze and understand how you use this website. These are the optional cookies that will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.
                                </p>
                                <a href="" className="policy">Read our full Privacy policy<i class="fas fa-external-link-alt"></i></a>
                                <h6>Mandatory</h6>
                                <p>Necessary cookies are essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.</p>
                                <div className='privacy--container'>
                                    <h6>Mandatory cookies</h6>
                                    <p>Always enabled</p>
                                </div>
                                <hr/>
                                <h6>Optional</h6>
                                <p>Cookies that may not be essential for the website to function and that are used specifically to collect user personal data via analytics, ads and other embedded content (such a Google Analytics and Hotjar) are termed as non-necessary, or optional cookies. It is mandatory to request and receive user consent prior to running these cookies on your website.</p>
                                <div className='privacy--container'>
                                    <h6>Optional cookies</h6>
                                    <p><Switch /></p>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <a className='mybtn privacy--accept mybtn-primary-transparent' onClick={accept}>Accept</a>
                    </div>
                </div>
            </div>
        </div>:""
        }
        </div>
    );
};

export default Privacy;