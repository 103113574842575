import React,{useState,useEffect} from 'react';
import './default_scenarios.scss';
import Scenario1 from './scenario1'
import Scenario1_mobile from './scenario1-mobile';
import Scenario2 from './scenario2'
import Scenario2_mobile from './scenario2-mobile';
import Scenario3 from './scenario3'
import Scenario3_mobile from './scenario3-mobile';
import Scenario4 from './scenario4'
import Scenario4_mobile from './scenario4-mobile';
import Scenario5 from './scenario5'
import Scenario5_mobile from './scenario5-mobile';
import Scenario6 from './scenario6'
import Scenario6_mobile from './scenario6-mobile';
import Scenario6_cpt from './scenario6_cpt'
import Scenario6_cpt_mobile from './scenario6_cpt-mobile';

const DefaultScenario = () => {
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        deviceCheck(480);
      })
    function deviceCheck(deviceWidth){
        window.screen.width <= deviceWidth ?
        setMobile(true)
        :
        setMobile(false)
      }
  return (
    <div className='default_scenarios'>
        <p>WROSE was initially developed with five scenarios selected as most relevant/appropriate to
            waste management for South African Municipalities. Each technology and scenario in the model
            aims to aid the waste managers in determining a final decision. WROSE outcomes are
            case-specific – strategies and scenarios can be tailored to suit individual municipal needs.
        </p>
        {mobile ? <Scenario1_mobile/> : <Scenario1/> }
        {mobile ? <Scenario2_mobile/> : <Scenario2/> }
        {mobile ? <Scenario3_mobile/> : <Scenario3/> }
        {mobile ? <Scenario4_mobile/> : <Scenario4/> }
        {mobile ? <Scenario5_mobile/> : <Scenario5/> }
        {mobile ? <Scenario6_mobile/> : <Scenario6/> }
        {mobile ? <Scenario6_cpt_mobile/> : <Scenario6_cpt/> }
    </div>
  )
}

export default DefaultScenario